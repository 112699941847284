<template>
  <div class="market-place-rewards">
    <p class="text-center" v-if="walletListData.length === 0">尚未建立任何錢包</p>

    <WalletCard 
      v-for="(wallet) in walletListData" 
      :key="wallet.serial" 
      :wallet="wallet" 
      @titleClicked="openWalletDetailModal"
      @withdrawClicked="openWithdrawSubmitModal"
    ></WalletCard>

    <transition name="fade">
      <FullScreenModal v-if="showWalletModal" @back="closeWalletDetailModal" title="回饋金紀錄">
        <WalletDetailModal :wallet="detailWallet" />
      </FullScreenModal>
    </transition>

    <transition name="fade">
      <FullScreenModal v-if="showWithdrawSubmitModal" @back="closeWithdrawSubmitModal" title="提領回饋金">
        <WithdrawSubmitModal :wallet="detailWallet" @close="closeWithdrawSubmitModal"/>
      </FullScreenModal>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WalletCard from '@/components/share/WalletCard.vue';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WalletDetailModal from './WalletDetailModal.vue';
import WithdrawSubmitModal from './WithdrawSubmitModal.vue';

export default {
  name: 'MarketPlaceRewards',
  data() {
    return {
      readingBusyName: 'MARKETPLACEREWARDS',
      
      showWalletModal: false,
      showWithdrawSubmitModal: false,
      detailWallet: {},

      init: false,
    };
  },
  components: {
    WalletCard,
    FullScreenModal,
    WalletDetailModal,
    WithdrawSubmitModal,
  },
  computed: {
    ...mapState('wallet', ['walletListData']),
  },
  watch: {
  },
  async mounted() {
    await this.refreshWalletList();
  },
  methods: {
    ...mapActions('wallet', ['refreshWalletList']),
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
    
    openWalletDetailModal(wallet) {
      this.detailWallet = Object.assign({}, this.detailWallet, wallet);
      this.showWalletModal = true;
    },
    openWithdrawSubmitModal(wallet) {
      this.detailWallet = Object.assign({}, this.detailWallet, wallet);
      this.showWithdrawSubmitModal = true;
    },
    closeWalletDetailModal() {
      this.showWalletModal = false;
    },
    closeWithdrawSubmitModal() {
      this.showWithdrawSubmitModal = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.market-place-rewards {
  padding: 0 .5rem;
}
</style>
  