<template>
  <div class="wallet-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-4">
            <b-img class="w-100" thumbnail fluid :src="wallet.businessAccount.logoImg" :alt="wallet.businessAccount.name"></b-img>
          </div>

          <div class="col">
            <h5 class="font-weight-bold mb-4" @click="titleClicked">{{ wallet.businessAccount.name }}</h5>

            <div class="row justify-content-between">
              <div class="col-auto">
                <p class="font-weight-bold">可提領</p>
              </div>

              <div class="col-auto">
                <p class="">NTD {{ wallet.points }}</p>
              </div>
            </div>
          </div>
        </div>

        <b-button @click="withdrawClicked">申請提領</b-button>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WalletCard',
  data() {
    return {
      
    }
  },
  created() {
  },
  components: {
  },
  computed: {
    
  },
  mounted() {
  },
  props: {
    wallet: {
      type: Object,
      required: true,
    }
  },
  watch: {
    
  },
  methods: {
    titleClicked() {
      this.$emit('titleClicked', this.wallet);
    },
    withdrawClicked() {
      this.$emit('withdrawClicked', this.wallet);
    }
  }

}
</script>

<style lang="scss" scoped>
  .wallet-card {
    position: relative;

    .btn {
      background-color: #404040;
      width: 100%;
      color: #fff;
    }
  }
</style>
